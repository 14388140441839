import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import Style from "styles/privacy.module.scss";

const Privacy = () => (
  <Layout>
    <SEO title="Privacy Policy" path="privacy" />
    <div className={Style.container}>
      <div className={Style.ctIntro}>
        <div className={Style.ctIntro__inner}>
          <h1 className={Style.ctIntro__title}>PRIVACY POLICY</h1>
        </div>
      </div>
      <section className={Style.privacyPolicy}>
        <h3 className={Style.ctIntro__subtitle}>プライバシーポリシー（個人情報保護方針）</h3>
        <p className={Style.ctIntro__date}>制定日 2021年5月13日</p>
        <p className={Style.ctIntro__date}>改定日 2023年5月25日</p>
        <p className={Style.privacyPolicy__sub}>
          株式会社イーゼ(以下、「当社」という。)は、「安全なＩＴ社会の実現」を理念として掲げ、人材サービス、ＥＣサービスの提供を行っております。
          <br />
          当社は、当社の事業の用に供するすべての個人情報を適切に取扱うため、当社全従業者が遵守すべき行動基準として本個人情報保護方針を定め、その遵守の徹底を図ることといたします。
        </p>
        <ul className={Style.privacyPolicyList}>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              1．当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。そのため、日本工業規格「個人情報保護マネジメントシステム
              — 要求事項」(JIS Q 15001)に準拠した個人情報保護マネジメントシステムを策定し、適切に運用いたします。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              2．当社は、事業の内容及び規模を考慮した適切な個人情報の取得、利用及び提供を行います。それには特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行わないこと及びそのための措置を講じることを含みます。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              3．当社は、個人情報の取扱いの全部又は一部を委託する場合は、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行います。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              4．当社は、本人の同意がある場合又は法令に基づく場合を除き、個人情報を第三者に提供することはありません。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              5．当社は、個人情報の漏えい、滅失又はき損の防止及び是正のための措置を講じます。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              6．当社は、個人情報の取扱いに関する苦情及び相談への適切かつ迅速な対応に努めます。また、当社が保有する保有個人データの開示等の請求(利用目的の通知、開示、訂正・追加又は削除、利用又は提供の停止）を受け付けます。開示等の請求の手続きにつきましては、以下の「個人情報苦情及び相談窓口」までご連絡ください。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              7．当社は、個人情報保護マネジメントシステムの継続的改善を行ないます。
            </p>
          </li>
        </ul>
        <div className={Style.privacyPolicySignature}>
          <p className={Style.privacyPolicySignature__text}>
            株式会社イーゼ
            <br />
            代表取締役　　石井　幸治
          </p>
        </div>
      </section>
      <section className={Style.privacyPolicy}>
        <h3 className={Style.ctIntro__subtitle}>個人情報の取扱いについて</h3>
        <ul className={Style.privacyPolicyList}>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}>
              株式会社イーゼ（以下当社といいます。）は、当社にご提供いただいた個人情報の重要性を認識し、
              個人情報保護に関する法律及び関連法令を遵守し、個人情報の保護に関する基本方針として次のとおりプライバシーポリシーを定め、
              これを全ての役員及び従業員に周知徹底し、個人情報の保護に努めます。
              <br />
              当社は、このプライバシーポリシーを継続的に見直し改善いたします。
              改定する場合には、このページにてお知らせいたします。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>個人情報の収集と利用</h2>
            <p className={Style.privacyPolicyList__text}>
              当社は、個人情報の利用目的をできる限り具体的に特定し、ご本人に明示、通知又は公表いたします。
              <br />
              ご本人に明示、通知又は公表した利用目的以外には、ご本人の同意なく個人情報を利用いたしません。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>個人情報の適正管理</h2>
            <p className={Style.privacyPolicyList__text}>
              当社は、個人情報への不正なアクセスや漏洩等を防ぐため、セキュリティーの維持に努めます。
              <br />
              個人情報の散逸、紛失、改ざん、漏洩などのないよう適切な措置をとり、個人情報を安全に管理します。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>開示と開示請求</h2>
            <p className={Style.privacyPolicyList__text}>
              当社は、ご本人からの個人情報及び第三者提供記録についてのお問い合わせに対し、法令に基づき誠実に対応いたします。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>1. 個人情報の利用目的(間接的に取得するもの、又は書面以外で取得するものについて)</h2>
            <p className={Style.privacyPolicyList__text}>
              当社が取得した個人情報は、以下に定める当社の事業活動の遂行のために、以下の利用目的の範囲内で利用させていただきます。
              <br />
              ただし、当社が取得する際に別途利用目的を明示又は通知した個人情報については、
              その利用目的の範囲内で利用させていただくものとします。
            </p>
            <p className={Style.privacyPolicyList__text}>
              （1）職務経歴書等は当社における受入れ作業員選考及び受入れ条件確立のため使用いたします。
            </p>
            <p className={Style.privacyPolicyList__text}>
              （2）求人媒体から提供される採用応募者情報は当社における採用業務のため使用いたします。
            </p>
            <p className={Style.privacyPolicyList__text}>
              （3）モニタリング等で取得した画像及び映像は当社運営における安全管理措置のため使用いたします。
            </p>
            <p className={Style.privacyPolicyList__text}>
              （4）口頭もしくはお電話にて取得した個人情報は弊社サービス提供のため使用いたします。
            </p>
            <p className={Style.privacyPolicyList__text}>
              （5）CONTACT(お問合せ)フォームに入力頂いた情報はお問合せにご回答させていただくため当社内の対応に使用いたします。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>2. 第三者への開示条件</h2>
            <p className={Style.privacyPolicyList__text}>
              当社が保有する保有個人データ及び第三者提供記録の開示等の求め(利用目的の通知、開示、訂正・追加又は削除、利用又は提供の停止）を受け付けます。
              <br />
              開示等の求めの手続きにつきましては、以下の「個人情報苦情及び相談窓口」までご連絡ください。
              <br />
              ご連絡いただいた後、迅速に対応させていただきます。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>3. その他</h2>
            <p className={Style.privacyPolicyList__text}>
              その他、当社の個人情報の取扱いに関するご質問、苦情などについては、弊社までお問い合わせください。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <p className={Style.privacyPolicyList__text}></p>
          </li>
        </ul>
      </section>
      <section className={Style.privacyPolicy}>
        <h3 className={Style.ctIntro__subtitle}>当社の保有個人データについて</h3>
        <h4 className={Style.smallText}>
          (個人情報保護法及びJISに基づく「本人の知り得る状態（本人の求めに応じて遅滞なく回答する場合を含む。）」に置く事項)
        </h4>
        <ul className={Style.privacyPolicyList}>
          <li className={Style.privacyPolicyList__item}>
            <h2>1. 個人情報取扱事業者の名称及び住所並びに代表者の氏名</h2>
            <p className={Style.privacyPolicyList__text}>
              名称：株式会社イーゼ
              <br />
              住所：東京都千代田区神田小川町3-6-1 栄信ビル7階
              <br />
              代表者の氏名：代表取締役　石井　幸治
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>2. 個人情報保護管理者の職名、所属及び連絡先</h2>
            <p className={Style.privacyPolicyList__text}>
              システム部　部長　（連絡先は下記「個人情報苦情及び相談窓口」）
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>3. すべての保有個人データの利用目的</h2>
            <ul className={Style.privacyPolicyList}>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (1) お客様の個人情報
                  <br />
                  　・ご契約内容を実施し適切に管理するため
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (2) お取引先様の個人情報
                  <br />
                  　・お取引先様との間のご契約内容を適切に管理するため
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (3) 株主の皆様の個人情報
                  <br />
                  　・会社法及び商法に基づく権利の行使・義務の履行のため
                  <br />
                  　・当社から各種便宜を供与するため
                  <br />
                  　・株主と会社の関係の円滑化を図るための各種の施策を実施するため
                  <br />
                  　・各種法令に基づき所定の基準による株主のデータを作成する等、株主管理のため
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (4) 当社への入社を希望される皆様の個人情報
                  <br />
                  　・就職先としてご興味をお持ちになった方並びにご応募いただいた方への採用、募集情報等の提供・連絡のため
                  <br />
                  　・採用選考業務のため
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (5) 当社の社員の個人情報
                  <br />
                  　・業務上の連絡、社員名簿の作成、法律上要求される諸手続(本人退職後も含む)、その他雇用管理のため。
                  <br />
                  　・人事選考、配属先および出向、派遣先の決定のため。
                  <br />
                  　・報酬の決定および支払、税務処理、社会保険関連の手続き、福利厚生の提供のため。
                  <br />
                  　・ビデオ及びオンラインによるモニタリング等における安全管理措置のため。
                  <br />
                  　・当社PR又は宣伝資料等における当社PR又は宣伝活動等のため。
                  <br />
                  　・適正な健康管理のため。(健康診断の結果等の労働者の健康情報については、法令に基づく場合を除いて、取得、利用又は提供を行いません。)
                  <br />
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (6) 当社へお問合せ頂いた方の個人情報
                  <br />
                  　・当社の接客態度等の向上のため
                  <br />
                  　・お問い合わせやご連絡内容を正確に把握し、対処するため
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (7) 特定個人情報等
                  <br />
                  　・源泉徴収票・支払調書作成事務、健康保険・厚生年金保険届出事務、雇用保険届出事務を法令に基づき適切に実施するため
                  <br />
                  　　※上記利用目的において、「ご契約内容を適切に管理するため」としているものは、「契約に入る前の段階における利用」と「契約終了後における利用」を含みます。
                </p>
              </li>
            </ul>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>4. 保有個人データの取扱いに関する苦情の申し出先</h2>
            <p className={Style.privacyPolicyList__text}>・「個人情報苦情及び相談窓口」（末尾に記載）</p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>5. 認定個人情報保護団体の名称及び苦情の解決の申し出先</h2>
            <p className={Style.privacyPolicyList__text}>
              ・現在当社は、認定個人情報保護団体の対象事業者ではございません。
            </p>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>6. 開示等の請求等に応じる手続</h2>
            <ul className={Style.privacyPolicyList}>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (1) 開示等の請求等の申し出先
                  <br />
                  　・「個人情報苦情及び相談窓口」（末尾に記載）
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (2) 開示等の請求等に際して提出すべき書面の様式その他の開示等の請求等の方式
                  <br />
                  　下記を当社「個人情報苦情及びご相談窓口」まで、郵便又は宅配便でご送付ください。遅滞なく迅速に対応いたします。なお郵便又は宅配便以外の手段（直接のご来社、電子メール、FAXなど）での開示等の請求等は受け付けておりません。
                </p>

                <ul className={Style.privacyPolicyList}>
                  <li className={Style.privacyPolicyList__item}>
                    <p className={Style.privacyPolicyList__text}>
                      a) 当社指定の「開示等の請求等申請書」
                      <br />
                      　お手元にない場合は、ご連絡ください。こちらからお送りいたします。
                    </p>
                  </li>
                  <li className={Style.privacyPolicyList__item}>
                    <p className={Style.privacyPolicyList__text}>b) 本人確認書類</p>
                    <ul className={Style.privacyPolicyList}>
                      <li className={Style.privacyPolicyList__item}>
                        <p className={Style.privacyPolicyList__text}>
                          1) ご本人によるお申し込みの場合
                          <br />
                          　・本人の公的証明書（写し）
                        </p>
                      </li>
                      <li className={Style.privacyPolicyList__item}>
                        <p className={Style.privacyPolicyList__text}>
                          2) 代理人によるお申し込みの場合
                          <br />
                          　下記の書類のうち該当するもののすべて。
                        </p>
                        <ul className={Style.privacyPolicyList}>
                          <li className={Style.privacyPolicyList__item}>
                            <p className={Style.privacyPolicyList__text}>① 代理権確認書類</p>
                            <ul className={Style.privacyPolicyList}>
                              <li className={Style.privacyPolicyList__item}>
                                <p className={Style.privacyPolicyList__text}>
                                  i. 法定代理人
                                  <br />
                                  　戸籍謄本その他その資格を証明する書類
                                </p>
                              </li>
                              <li className={Style.privacyPolicyList__item}>
                                <p className={Style.privacyPolicyList__text}>
                                  ii. 任意代理人（以下のすべて）
                                  <br />
                                  　・当社指定の委任状（当社指定の委任状がお手元にない場合は、ご連絡ください。早急にご郵送します。）
                                  <br />
                                  　　※印鑑登録された本人の印鑑の押印がされたもの。
                                  <br />
                                  　・本人の印鑑登録証証明書
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li className={Style.privacyPolicyList__item}>
                            <p className={Style.privacyPolicyList__text}>
                              ② 代理人の身元確認書類
                              <br />
                              　・代理人の公的証明書（写し）
                            </p>
                          </li>
                          <li className={Style.privacyPolicyList__item}>
                            <p className={Style.privacyPolicyList__text}>
                              ③ 本人の身元確認書類
                              <br />
                              　・本人の公的証明書（写し）
                            </p>
                          </li>
                          <li className={Style.privacyPolicyList__item}>
                            <p className={Style.privacyPolicyList__text}>
                              ※公的証明書
                              <br />
                              以下、アまたはイを言う。
                            </p>
                            <ul className={Style.privacyPolicyList}>
                              <li className={Style.privacyPolicyList__item}>
                                <p className={Style.privacyPolicyList__text}>
                                  ア. 以下のいずれかをひとつ
                                  <br />
                                  　運転免許証、運転経歴証明書、旅券、個人番号カード（氏名と写真の表示がある面のみ）、在留カード、特別永住者証明書
                                </p>
                              </li>
                              <li className={Style.privacyPolicyList__item}>
                                <p className={Style.privacyPolicyList__text}>
                                  イ. 以下の書類をふたつ以上
                                  <br />
                                  　公的医療保険の被保険者証、住民票の写し、住民票記載事項証明書、年金手帳、後期高齢者医療の被保険者証、国家公務員共済組合又は地方公務員共済組合の組合員証、私立学校教職員共済制度の加入者証、児童扶養手当証書、特別児童扶養手当証書
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li className={Style.privacyPolicyList__item}>
                            <p className={Style.privacyPolicyList__text}>
                              ※ご提示いただいた本人確認書類は、以下の通り利用いたします。ご同意いただける方のみ、開示等の請求等をお願いいたします。
                              <br />
                              ・当個人情報は、本人からの開示等の請求等に応じるために利用いたします。
                              <br />
                              ・本人確認に必要のない要配慮個人情報については墨塗りするなどして判読できないようにしてください。
                              <br />
                              ・法令に基づく場合を除いて、当個人情報を本人の同意なく第三者に提供することはありません。また、当個人情報の取扱いを委託する予定はありません。
                              <br />
                              ・本人確認書類等により本人確認が出来ない場合、開示等の請求等に応じられない場合がございます。
                              <br />
                              ・その他、当社の個人情報の取扱いについては当社のホームページをご覧下さい。
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className={Style.privacyPolicyList__item}>
                    <p className={Style.privacyPolicyList__text}>
                      c) 手数料
                      <br />
                      　「利用目的の通知」あるいは「開示」につきましては、1件のお申込みにつき手数料として1,000円いただきます。1,000円分の郵便小為替を上記書類にあわせてご同封ください。
                      <br />
                      　上記の通り手数料が同封されていなかった場合は、その旨ご連絡申し上げますが、所定の期間内にお支払いいただけない場合は開示等の請求等がなかったものといたします。なお、送付頂いた書類は原則としてご返却いたしません。
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={Style.privacyPolicyList__item}>
            <h2>7 保有個人データの安全管理のために講じた措置</h2>
            <ul className={Style.privacyPolicyList}>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (基本方針の策定)
                  <br />
                  個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針を策定しております。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (個人データの取扱いに係る規律の整備)
                  <br />
                  取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人データの取扱規程を策定しております。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (組織的安全管理措置)
                  <br />
                  個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備
                  しております。
                  <br />
                  個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施しております。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (人的安全管理措置)
                  <br />
                  個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しております。
                  <br />
                  個人データについての秘密保持に関して従業者と契約を締結しております。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (物理的安全管理措置)
                  <br />
                  個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の管理を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施しております。
                  <br />
                  個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を実施しております。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (技術的安全管理措置)
                  <br />
                  アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範）個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しております。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  (外的環境の把握)
                  <br />
                  個人データの取扱いについては外国にある第三者には提供しておりません。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  ※注意事項
                  <br />
                  ・郵送や配送途中の書類の紛失、事故による未着につきましては、当社では責任を負いかねます。
                  <br />
                  ・必要事項を全てご記入下さい。書類に不備がある場合は、返送させていただく場合がございます。
                  <br />
                  ・個人情報保護法の例外規定にあたる場合など、開示等の請求等にお答えできない場合がございます。
                  <br />
                  ・内容により、お時間をいただく場合がございます。
                  <br />
                  ・開示等の請求等にともない収集した個人情報は、開示等の請求等に必要な範囲のみで取扱うものとします。提出していただいた書面は当社で適切に廃棄いたします。
                  <br />
                  ・「訂正等」、「利用停止等」の結果、該当するサービスがご利用いただけなくなることがあります。あらかじめご了承下さい。
                </p>
              </li>
              <li className={Style.privacyPolicyList__item}>
                <p className={Style.privacyPolicyList__text}>
                  ※「保有個人データ」とは、電子計算機を用いて検索することができるように体系的に構成した情報の集合物又は一定の規則に従って整理、分類し、目次、索引、符合などを付すことによって特定の個人情報を容易に検索できるように体系的に構成した情報の集合物を構成する個人情報であって、当社が、本人から求められる開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の求めのすべてに応じることができる権限を有するものをいいます。詳しくは、「個人情報苦情及びご相談窓口」までご連絡ください。
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <div className={Style.privacyPolicyInfo}>
          <h2>【個人情報苦情及び相談窓口】</h2>
          <p>
            〒101-0052　東京都千代田区神田小川町3-6-1 栄信ビル7階
            <br />
            株式会社イーゼ　システム部　個人情報問合せ窓口
            <br />
            TEL：03-5244-5955
            <br />
            (受付時間 月曜日～金曜日 祝祭日を除く 10：00‐17：00)
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default Privacy;
